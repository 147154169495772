:root {
    --email-color: #FF0000;
    --twitter-color: #1DA1F2;
    --spotify-color: #1DB954;
    --soundcloud-color: #FF3300;
    --youtube-solo-color: #FF0000;
    --youtube-trio-color: #FF0000;
    --discord-color: #7289DA;
    --gamebanana-color: #fbff00;
    --github-color: #181717;
    --gamejolt-color: #602000;
    --steam-color: #171A21;
    --epic-games-color: #2C2C2C;
    --playstation-color: #003791;
    --minecraft-color: #009688;
    --tetrio-color: #800000;
}

* {
    transition: all .1s forwards linear !important;
}


.social-email {
    background-color: var(--email-color);
    color: white;
}

.social-email:hover {
    background-color:white;
    color: var(--email-color) !important;
}

.social-twitter {
    background-color: var(--twitter-color);
    color: white;
}

.social-twitter:hover {
    background-color:white;
    color: var(--twitter-color) !important;
}

.social-spotify {
    background-color: var(--spotify-color);
    color: white;
}

.social-spotify:hover {
    background-color:white;
    color: var(--spotify-color) !important;
}

.social-soundcloud {
    background-color: var(--soundcloud-color);
    color: white;
}

.social-soundcloud:hover {
    background-color:white;
    color: var(--soundcloud-color) !important;
}

.social-youtube-solo {
    background-color: var(--youtube-solo-color);
    color: white;
}

.social-youtube-solo:hover {
    background-color:white;
    color: var(--youtube-solo-color) !important;
}

.social-youtube-trio {
    background-color: var(--youtube-trio-color);
    color: white;
}

.social-youtube-trio:hover {
    background-color:white;
    color: var(--youtube-trio-color) !important;
}

.social-discord {
    background-color: var(--discord-color);
    color: white;
}

.social-discord:hover {
    background-color:white;
    color: var(--discord-color) !important;
}

.social-gamebanana {
    background-color: var(--gamebanana-color);
    color: black;
}

.social-gamebanana:hover {
    background-color:black;
    color: var(--gamebanana-color) !important;
}

.social-github {
    background-color: var(--github-color);
    color: white;
}

.social-github:hover {
    background-color:white;
    color: var(--github-color) !important;
}

.social-gamejolt {
    background-color: var(--gamejolt-color);
    color: white;
}

.social-gamejolt:hover {
    background-color:white;
    color: var(--gamejolt-color) !important;
}

.social-steam {
    background-color: var(--steam-color);
    color: white;
}

.social-steam:hover {
    background-color:white;
    color: var(--steam-color) !important;
}

.social-epic-games {
    background-color: var(--epic-games-color);
    color: white;
}

.social-epic-games:hover {
    background-color:white;
    color: var(--epic-games-color) !important;
}

.social-playstation {
    background-color: var(--playstation-color);
    color: white;
}

.social-playstation:hover {
    background-color:white;
    color: var(--playstation-color) !important;
}

.social-minecraft {
    background-color: var(--minecraft-color);
    color: white;
}

.social-minecraft:hover {
    background-color:white;
    color: var(--minecraft-color) !important;
}

.social-tetrio {
    background-color: var(--tetrio-color);
    color: white;
}

.social-tetrio:hover {
    background-color:white;
    color: var(--tetrio-color) !important;
}

/*******************************************************************/



.img-email,
.img-twitter,
.img-spotify,
.img-soundcloud,
.img-youtube-solo,
.img-youtube-trio,
.img-discord,
.img-gamebanana,
.img-github,
.img-gamejolt,
.img-steam,
.img-epic-games,
.img-playstation,
.img-minecraft,
.img-tetrio {
    display: none;
    margin-top:15px;
    border:3px solid white;
    border-radius:20px;
}

.social-email,
.social-twitter,
.social-spotify,
.social-soundcloud,
.social-youtube-solo,
.social-youtube-trio,
.social-discord,
.social-gamebanana,
.social-github,
.social-gamejolt,
.social-steam,
.social-epic-games,
.social-playstation,
.social-minecraft,
.social-tetrio {
    width:100%;
    margin:auto;
    padding:5px;
    margin-top: 0px;
    border:1px solid white;
    border-radius: 10px;;

}


.img-old {
    display: inline;
    margin: auto;
    margin-top:15px;
    border:3px solid white;
    border-radius:20px;
}