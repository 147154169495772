* {
    transition: all .3s;
    margin:0;
    padding:0;
}

p, h1, h2, h4, h5, h6 {
    margin-top:18px;
    margin-bottom:18px;
}

.colorWhiteHahahahahahaha {
    color:white
}

h3 {
    color:white;
    text-align: center;
}

#root {
    margin-left:0px;
}

body {
    background-color: black;
    margin: 0;
    
}

nav {
    position: fixed;
    top: 0;
    z-index: 999; /* STAY ON TOP!! */   
}

nav div {
    background-color: black;
    color: grey;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--top-width);
    width: calc(13.60vw - 2.5px);
    height: 40px;
    float: left;
    text-decoration: none;
    font-weight: bold;
    border-bottom: 5px solid white;
    user-select: none;

}

nav .loginButton {
    background-color: black;
    color: grey;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--top-width);
    width: 5vw;
    height: 60px;
    float: left;
    text-decoration: none;
    font-weight: bold;
    border-bottom: 5px solid white;
    user-select: none;
}

nav .navLogin {
    width:48px;
    border:1px solid white;
    overflow: hidden;
    display:block;
}

nav .loginButton:hover {
    height: 80px;
}

nav .loginButton:active {
    height:90px;
    border-bottom: 10px solid white;
}

nav div:hover {
    height: 55px;
}

nav div:active {
    height: 85px;
}

nav div:hover {
    background-color: #373737
}

nav .active1 {
    border-bottom: 5px solid lime;
}

nav .active2 {
    border-bottom: 5px solid orange;
}

nav .active3 {
    border-bottom: 5px solid #0096ff;
}

nav .active4 {
    border-bottom: 5px solid yellow;
}

nav .active5 {
    border-bottom: 5px solid #aa00ff;
}

nav .active6 {
    border-bottom: 5px solid red;
}

nav .active7 {
    border-bottom: 5px solid #ff00e6;
}

nav .activeAcc {
    border-bottom: 5px solid green;
}

@keyframes shakeAnim {
    0%, 100% {
        transform: translateX(0);
        color: white
    }
    10%, 30%, 50%, 70%, 90% {
        transform: translateX(-5px);
        color: darkred;
    }
    20%, 40%, 60%, 80% {
        transform: translateX(5px);
        color: #5c0000;
    }

}

@keyframes shakeAnimBadder {
    0%, 100% {
        transform: translateX(0);
        border: 5px solid white;
    }
    10%, 50%, 90% {
        transform: translateX(-5px);
    }
    20%, 60% {
        transform: translateX(5px);
    }
    30%, 70% {
        transform: translateY(5px);
    }
    40%, 80% {
        transform: translateY(-5px);
    }

}

.shake {
    animation: shakeAnim 1s linear forwards !important;
}

.shakeBadder {
    animation: shakeAnimBadder .1s ease-in-out infinite !important;
}

h1 {
    text-align: center;
    width: 100%;
    color: white;
    font-size: 60px;
    margin-top: 75px;

}

main {
    width: 90vw;
    margin: auto;
}

.box {
    margin-top: 20px;
    border: 3px solid white;
    padding: 10px;
    box-shadow: 2px 2px 2px white;
}

.boxImg {
    width:90%;
    margin:auto;
    border:5px solid white;
}

.box .projButton {
    width:80%;
    margin-bottom: 10px;
    border:3px solid white;
    color:white;
    text-align: center;
    background-color: black;
    padding-top:5px;
    padding-bottom:5px;
}

.box .projButton:hover {
    width:85%;
    border:5px solid white;
    background-color: white;
    color:black;
}

.box .projButton:active {
    background-color: black;
    color:white;
    
}

.box h3 {
    color:white;
    text-align:center;
    
}

.box:hover {
    background-color: black;
    box-shadow: 4px 4px 4px white;
}

.box .mainPfp {
    width: 75px;
    border: 3px solid white;
    float: right;
}

.top-box p {
    color: white;
}

.h3-right {
    display: inline-block;
    color: white;
}

.box-left {
    color: white;
    text-align: center;
    margin-top: 10px;
    float: left;
    width: 62vw;
}

.box-right {
    color: white;
    text-align: center;
    margin-top: 10px;
    float: right;
    width: 22vw;
}

.box-left-projects {
    color: white;
    text-align: center;
    margin-top: 10px;
    float: left;
    margin-left: 5vw;
    width: 38vw;
}

.box-right-projects {
    color: white;
    text-align: center;
    margin-top: 10px;
    float: right;
    margin-right: 5vw;
    width: 38vw;
}


.main-box-container::after {
    content: "";
    display: table;
    clear: both;
}

.box-right button {

}

.box-right button .b {
    display: none;
}

.box-right button:hover .b {
    display: inline;
}

.box-right button .a {
    display: inline;
}

.box-right button:hover .a {
    display: none;
}

.warning {
    background-color: black;
    color: darkred;
    width: 90%;
    margin: auto;
    padding: 10px;
    margin-top: 75px;
    overflow: hidden;
    border: 3px solid darkred;
    border-radius: 20px;
    color: white;
    text-align: center;
    box-shadow: 0px 10px 20px darkred;
    animation: warningPulse 3s infinite linear;
}

.warning img {
    width: 65px;
    margin-bottom: -20px;
    
}

@keyframes warningPulse {
    0%, 100% {
        border: 3px solid darkred;
        box-shadow: 0px 10px 20px darkred;
    }

    50% {
        border: 3px solid #5c0000;
        box-shadow: 0px 10px 20px #5c0000;
    }
    
}

.issue {
    background-color: black;
    color: darkgoldenrod;
    width: 90%;
    margin: auto;
    padding: 10px;
    margin-top: 75px;
    overflow: hidden;
    border: 3px solid darkgoldenrod;
    border-radius: 20px;
    color: white;
    text-align: center;
    box-shadow: 0px 10px 20px darkgoldenrod;
    animation: issuePulse 3s infinite linear;
}

.issue img {
    width: 65px;
    margin-bottom: -20px;
    
}

@keyframes issuePulse {
    0%, 100% {
        border: 3px solid darkgoldenrod;
        box-shadow: 0px 10px 20px darkgoldenrod;
    }

    50% {
        border: 3px solid #605800;
        box-shadow: 0px 10px 20px #605800;
    }
    
}

.box-right h4, .box-left h4 {
    margin: 0;
    margin-top: 5px;
}

::-webkit-scrollbar {
    width: 10px;
}

.projWrapper {
    overflow: hidden;
    text-align: left;
    padding-bottom: 10px;
}

.projWrapper h4 {
    margin-top: -15px;
    margin-bottom: -10px;
}

.projWrapper .lang {
    user-select: none;
    padding: 5px;
    color: white;
    border: 1px solid white;
    display: inline;
    margin-left: 10px;
    margin-right: 10px;
}

.projWrapper h3 {
    font-size: 40px;
    text-align: center;
}

.projLink {
    user-select: none;
    margin-top: 20px;
    color: white;
    padding: 10px;
    width: 200px;
    height: 18px;
    background-color: black;
    border: 1px solid white;
    transform: translateX(10px);
}

.projLink:hover {
    height: 24px;
    transform: skewX(-20deg) translateX(15px)
}

.projLink:active {
    transform: skewX(-20deg) translateX(8px)
}

.projLink:hover > .projLink-box {
    transform: rotate(45deg) translate(14px, -20px);
    width: 31px;
    height: 31px;
}

.projLink-box {
    background-color: black;
    border: 1px solid white;
    float: right;
    transform: rotate(45deg) translate(14px, -20px);
    width: 25px;
    height: 25px;
}

.projLinkAnim {
    animation: linkAnimBody 1s forwards;
}

.projLinkAnim > .projLink-box {
    animation: linkAnimHead 1s forwards;
}

.projLinkAnimBack {
    animation: linkAnimBack 1s forwards;
}

.box {
    overflow: hidden;
}

@keyframes linkAnimBody {
    0% {
        width: 200px;
        transform: skewX(-20deg) translateX(10px);
    }
    20% {
        width: 180px;
        transform: skewX(-20deg) translateX(10px);
    }
    50% {
        width: 200px;
        transform: skewX(-20deg) translateX(10px);
    }
    80% {
        transform: skewX(-20deg) translateX(calc(100vw + 500px));
        height: 24px;
    }
    100% {
        transform: skewX(-20deg) translateX(calc(100vw + 500px));
        height: 1px;
    }
}

@keyframes linkAnimHead {
    10% {
        background-color: white;
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes linkAnimBack {
    0% {
        height: 1px;
        width: 200px;
        transform: skewX(-20deg) translateX(-999px);
    }

    80% {
        height: 24px;
        transform: skewX(-20deg) translateX(0);
    }

    100% {
        height: 19px;
        transform: skewX(0deg) translateX(10px);
    }
}

.divider {
    width: 20vw;
    margin-top: 30px;
    border: 3px solid white;
}

.statB, .statB a {
    color: darkred;
}

.statM, .statM a {
    color: darkgoldenrod;
}

.statG, .statG a {
    color: darkgreen;
}

.focus {
    color: cyan;
    margin-left: 15px;
    margin-top: -15px;
    
}

.hidden {
    display: none;
}

.aboutButton {
    user-select: none;
    width: 80%;
    height: 35px;
    color: white;
    border: 1px solid white;
    background-color: black;
    margin: auto;
    padding: 10px;
    text-align: center;
    user-select: none;
}

.aboutButton:hover {
    transform: translateY(5px);
}

.aboutButtonMove {
    animation: aboutLaunch 1s forwards;
}

.navButton {
    text-align: center;
}

.navButtonGlow {
    animation: aboutButtonGlow 1s forwards;
}

.aboutButtonBack {
    animation: aboutButtonBackA 1s forwards;
}

.aboutButtonBoxLeft, .aboutButtonBoxRight {
    width: 25px;
    height: 25px;
    background-color: white;
    display: inline-block;
    margin-top: 6px;
}

.aboutButtonBoxLeft {
    margin-right: 12vw;    
}

.aboutButtonBoxRight {
    margin-left: 12vw;
}

.aboutButton:hover > .aboutButtonBoxLeft {
    animation: aboutButtonSpinLeft 1s linear infinite;
}
.aboutButton:hover > .aboutButtonBoxRight {
    animation: aboutButtonSpinRight 1s linear infinite;
}

.aboutButtonP {
    display: inline-block;
    transform: translateY(-6px);
}

@keyframes aboutLaunch {
    0% {
        width: 80%;
        height: 35px;
        transform: translateY(0px);
    }
    10% {
        width: 95%;
        height: 20px;
        transform: translateY(0px);
    }
    20% {
        width: 80%;
        height: 35px;
        transform: translateY(0px);
    }
    90% {
        transform: translateY(-999px) rotate(35deg);
        height: 35px;
    }
    100% {
        transform: translateY(-999px) rotate(35deg);
        height: 0px;
    }

}

@keyframes aboutButtonGlow {
    0%, 40%, 100% {
        background-color: black;
        color: grey;
    }

    20%, 60% {
        background-color: grey;
        color: black;
    }
}

@keyframes aboutButtonBackA {
    0% {

        transform: translateY(-999px) rotate(35deg);
        height: 0px;
    }
    100% {
        transform: translateY(0px) rotate(0deg);
        height: 35px;
    }
}

@keyframes aboutButtonSpinLeft {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg)
    }
}

@keyframes aboutButtonSpinRight {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-360deg)
    }
}

footer {
    margin-top: 80px;
    background-color: #3F3F3F;
    position: relative;
    width: 100%;
    height: 100px;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    text-align: center;
    
}

#lilgrim {
    user-select: none;
    position: absolute;
    transform: translate(-30vw, -86px) scale(0.5);
}

.projWrapper {
    color: white;

}

.projImgLeft {
    width: 400px;
    float: left;
    margin-right: 25px;
    border: 3px solid white;
}

.projImgRight {
    width: 400px;
    float: right;
    margin-left: 25px;
    border: 3px solid white;

}

.projImgCenter {
    display:block;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border: 3px solid white;
}

.creditsWrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Add this line to enable wrapping */
    gap: 15px;
}

.creditsWrapper h2 {
    /* Empty Ruleset */
}

.credit {
    width: 100px;
    border: 3px solid white;
}

.credit img {
    margin-top: 10px;
    width: 50px;
    border: 1px solid white;
}

.credit p {
    margin-top: 1px;
}

.creditsTitle {
    font-size: 60px;
    margin: 0;
    margin-bottom: 20px;
}

.creditButton {
    background-color: black;
    color:white;
    border-top:3px solid white;
}

.creditButton:hover {
    background: white;
    color:black;
}

.update h2, .update h6, .update li {
    text-align: left;
}

.trophyContainer {
    display: flex;
    flex-wrap: wrap;
    width:100%;
    height:175px;
    margin-top:20px;
}

.trophyProfile {
    color:white;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    width:15%;
    min-width: 10%;
    height:75px;
    margin-top:10px;
    margin-left:1vw;
}

.trophyProfile img {
    border-radius:15px;
    border:3px solid white;
    margin-top:20px;
}

.trophyHead {
    display:inline-block;
    color:white;
    text-align: center;
    display: inline-block;
    width:100%;
    margin-top:-50px;
}

.trophyBox {
    flex:1;
    height:60px;
    float:left;
    width:24%;
    margin:auto;
    display:block;
    color:white;
}

.trophyBox img {
    float:left;
    height:50px;
    margin-right:10px;
}

h2 {
    color:white;
    text-align: center;
    font-size: 50px;
}

h4 {
    color:white;
}

/* Study, as its applying styles to the wrong stuffs
p {
    color:white;
}
*/

.limk {
    color:orange
}

.limk:hover {
    text-decoration: none;
}

.creditsBox {
    border:1px solid white;
    height:75px;
    margin-top: 10px;
}

.creditsBox p, .creditsBox h3 {
    color:white;
    margin-top:7px;
    margin-left:100px;
}

.creditsBox img {
    width:75px;
    float:left;
}

.creditsBox a {
    width:100px;
    height:69px;
    border:3px solid white;
    background-color: black;
    color:white;
    text-align: center;
    float:right;
    transform: translateY(-78px);
    text-decoration: none;
    display:flex;
    justify-content: center;
    align-items: center;
}


.accountPFP {
    border:5px solid white;
}

/* watch it
.box h6, .box h2 {
    margin-top:10px;
    margin-bottom:10px;
    text-align: left;
}
*/

.box input {
    width:50vw;
    display: block;
    padding:10px;
    background-color: black;
    color:white;
    font-size: 20px;
    border:3px solid white;
}

.box button {
    border:3px solid white;
    background-color: black;
    color:white;
    padding:5px;
}


.inputBoxCon {
    width:50px;
    height:50px;
    transform:translate(35vw, -49px);
    animation: inputBoxConAnim 30s infinite linear;
}

@keyframes inputBoxConAnim {
    0%, 100% {
        transform:translate(38vw, -49px)
    }
    50% {
        transform:translate(30vw, -49px)
    }
}

.inputBox {
    width:45px;
    height:45px;
    border:3px solid white;
    background-color: black;
    transform: rotate(45deg);
    animation: inputBoxAnim 1s infinite linear;
}

@keyframes inputBoxAnim {
    from {transform: rotate(45deg)}
    to {transform: rotate(135deg)}
}


/*
    I have an idea! Lets store this unused HTML and hide it in the CSS!

    <div>
        <button>
            <svg width="300" height="30" className="test">
                <polygon 
                    points="0 15, 50 0, 250 0, 300 15, 250 30, 50 30, 0 15"
                    style={{ fill: 'black', stroke: 'white', strokeWidth: '4px' height:{height} }}
                />
            </svg>
        </button>
    </div>
*/

.warningContainer {
    overflow:hidden;
    border-radius: 10px;
    width:100px;
    height:10px;
}
.warningSign {
    width: 200%;
    height: 200%;
    background: repeating-linear-gradient(-45deg, black, black 10px, red 10px, red 20px);
    transform: skew(-20deg), translate(-50%, -50%);
}

.grinput {
    border:5px solid white;
    width:80%;
    margin:auto;
    margin-top: 30px;
    margin-bottom: 30px;

}

.grimutton {
    display: block;
    border:5px solid white;
    width:80%;
    margin:auto;
    margin-top: 30px;
    margin-bottom: 30px; 
    padding:20px !important;
    font-size: 25px;
}

.grimutton:hover {
    background-color: white;
    color:black;
    border:2px solid black;
    width:calc(80% + 6px);
    font-size: 28px;
}